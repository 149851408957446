/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 */

$mela-brand: #f8b718;
$mela-blue: #06038D;
$primary: #333399;
$success: darken(#b5bb26, 5%);
$secondary: lighten(#9999CC, 20%);
$info: #9999CC;
$border-radius: 0;//1rem;
$nav-tabs-border-radius: 0;
$dropdown-border-radius: 0;
$headings-font-family: "Roboto Slab", serif;
$input-disabled-bg: lighten(#9999CC, 28%);

@import "bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;400;700&display=swap');
/* @import "font-awesome-sprockets";
@import "font-awesome";
@import "jquery-ui";
@import "fonts/fonts";
@import "modal";
@import "customer";
@import "icons";
@import "layout";
@import "table";
@import "user";
@import "settings";
@import "form_option";
@import "visit";
@import "farm";
@import "global_search";
@import "buttons";
@import "form_editor";
@import "web_links";
@import "messages";
@import "report";
@import "help";
@import "forms";
@import "material_icons";
@import "material_icon_contents";
@import "guides";
@import "common"; */

strong, .mela-blue{
    color: $mela-blue;
    font-family: $headings-font-family;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-family: $headings-font-family;
}
.black{
    color: #444;
}
.content-wrapper-wide {
    width: calc(100%);
    margin: 0 auto;
    max-width: 60rem;
}
.text-center-txt {
    text-align: center;
}
.text-left-txt {
    text-align: left;
}

.bullet-icon-size {
    width: 15px;
}

.sm-description {
    font-size: 0.85rem;
}
@media (min-width: 992px) {
    .container-small {
        max-width: 1000px;
    }
}  
/* @media (min-width: 1200px) {
    .container-small {
        max-width: 1200px;
    }
}   */

p{
    white-space: pre-line
}
ul {
    padding-left: 1.2rem;
}
ul li {
    list-style-position: outside;
}
.btn{
    vertical-align: top;
}
.btn svg{
    margin-bottom: 0.14rem;
}

.remind-container {
    border-style: solid;
    border-width: 2px;
    border-color: $mela-blue;
    align-items: center;
    margin: 1rem 0;
}

.maintenance-valid-container {
    border-style: solid;
    border-width: 1px;
    border-color: white;
    margin-bottom: 2rem;
    width: 25%;
    margin-left: 37%;
}
