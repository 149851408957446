.form-result{
  border: 2px solid #aaa;
}
.form-result-title{
  border-bottom: 2px solid #aaa;
  padding: 0.7rem 1rem;
}
.form-result-title *{
  margin-bottom: 0;
  font-weight: 800;
}
.form-result-content h6{
  font-weight: 800;
}
.form-result-content{
  padding: 0 2rem 2rem 2rem;
  color: #555;
  // text-align: justify;
}
.collapsing{
  height: auto;
}
.accordion-collapse:not(.show){
  display: inherit;
  transition: 0s height;
}
.accordion-button {
  color: #2e2e8a;
  background-color: #ebebf5;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.accordion-collapse > div > *:not(.collapsed-show){
  transition: 0.2s all;
  opacity: 1;

  max-height: 1000px;
}
.accordion-collapse:not(.show) > div > *:not(.collapsed-show) {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  margin: 0 !important;
  overflow: hidden;
}
.accordion-button:not(.collapsed)::after{  
  background-image: none;
/* background-image:;*/    
  transform: none; 
  opacity: 0;
}
.accordion-button.collapsed::after{
  /* background-image:;*/    
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
    transform: scale(0.75);
    opacity: 1;
}


.printing-message{
  background-color: black;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  line-height: 100vh;
  text-align: center;
  color: white;
  z-index: 999;
  transition: 0.1s all;
}

@media print { 
  *{
    break-before: none;
  }
  .btn, .footer, .dont-print { display: none !important; } 
  .accordion {page-break-inside: avoid;}
  html{-webkit-print-color-adjust: exact;}
  @page { 
    size: A4 portrait;
    margin: 0;
  } 
   body { margin: 1cm; }
   .container{
     max-width: none;
   }
}

.padding-left-2rem {
  padding-left: 2rem;
}

#icalendar-btn {
  width: 86%;
  margin-left: 0.7rem;

  @media (min-width: 400px){
    width: 88%
  };

  @media (min-width: 500px){
    width: 91%
  };

  @media (min-width: 768px){
    width: 92.5%
  };

  @media (min-width: 992px){
    width: 94.8%
  };

  @media (min-width: 1440px){
    width: 94%
  }

}

.exit-btn-container {
  padding-left: 0.5rem;
  padding-right: 2.35rem
}