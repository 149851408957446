.share-popup{
    position: fixed; display: block;
    top:50%; left: 50%; transform: translate(-50%, -50%);
    width: 600px;
    max-width: 92vw !important;
    height: auto;
    background-color: #fff;
    box-shadow: 2px 2px 15px rgb(105, 104, 104, 0.8),0 0 0 1500px rgb(105, 104, 104, 0.3);
    padding:1rem;
    visibility: hidden;
    opacity: 0;
    transition: 0.1s;
    margin-top: -2rem;
    z-index: 900;

}
.share-popup.show{
    visibility: visible;
    opacity: 1;
    margin-top: 0;
}