
body{
    margin:0;
    color:#666;
    font-family: 'Lato', "Lucida Grande", Tahoma, Sans-Serif;
    min-height: 100vh;
}
#app{
    min-height: 100vh;
}
h2, .h2, h1, .h1{
    font-weight: 800;
}

@media (min-width: 48em){
    html:not(.printing) {
        font-size: 105% !important;
    }
}
@media (min-width: 90em){
    html:not(.printing) {
        font-size: 125% !important;
    }
}

/* SM breakpoint */
@media (max-width: 575px) {
    /* CUSTOM WIDTHS */
    .w-xs-only-100 { width: 100%!important; }
}

html {
    font-family: Lato,sans-serif;
    font-size: 1rem;
    text-rendering: optimizeLegibility;
}
.site-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.site-header a {
    line-height: 3rem;
}

.site-header img {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 3rem;
    height: auto;
}

@media (min-width: 48em){
    .site-header img {
        max-height: 4rem;
    }
    .site-header a {
        line-height: 4rem;
    }
}

.float-right{
    float:right;
}
.float-left{
    float:left;
}
.footer{
    background-color: #333399;
    color:#fff;
    padding: 4em 0 4em 0;   
}
.footer a{
    color:#fff;
    text-decoration: none;
    margin-right: 0.6rem;
}
.footer a:hover{
    color:rgb(255, 195, 66);
}