
$mela-blue: #06038D;
.progress_indicator_wrapper{
    position: fixed;
    right: 0;
    top: 5vh;
    bottom: 5vh;
}
.progress_indicator_wrapper a {
    text-decoration: none !important;
    width: 100%;
    display: inline-block;
}
.progress_indicator_wrapper .progress_step{
    background-color: rgb(184, 184, 230);
    text-align: center;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    color: $mela-blue;
    font-weight: 800;
    z-index: 100;
    position: relative;
    cursor: pointer;
    width: 2.4em;
    transition: 0.1s all;
}
.progress_indicator_wrapper .progress_step:hover{
    transform: translateX(-0.25rem);
}
.progress_indicator_wrapper .progress_step.answered{
    background-color: white;
    text-align: center;
    border: 1px solid $mela-blue;
    z-index: 200;
    box-shadow: -1px 1px 5px rgba(0,0,0,0.3);
}
.progress_step:not(:first-child){
    margin-top: -1px;
}