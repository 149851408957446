
$mela-blue: #06038D;


.results-bar .step{
  width: 20%;
  height: 1.4rem;
  background-color: white;
  display: inline-block;
  position: relative;
  //box-shadow: -1px 0px inset white;
  //border-right: 2px solid #fff;
}
.results-bar.printing{
  // Used to normalize size among different device sizes
  width: 720px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: white !important;
}

.results-bar .step.verticalLine:after {
  content:"";
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 2px solid #fff;
  transform: translate(-50%);
}

.results-bar .step.bad{
  background-color: red;
}
.results-bar .step.med{
  background-color: rgb(255, 204, 0);
}
.results-bar .step.good{
  background-color: rgb(0, 153, 51);
}
.results-bar .step.very_good{
  background-color: rgb(0, 255, 85);
}

.results-bar .step.bad_bad{
  background-color: red;
}

.results-bar .step.bad_med{
  background-image: linear-gradient(to right, red, rgb(255, 204, 0));
}
.results-bar .step.med_med{
  background-color: rgb(255, 204, 0);
}
.results-bar .step.med_good{
  background-image: linear-gradient(to right, rgb(255, 204, 0), rgb(0, 153, 51));
}
.results-bar .step.good_good{
  background-color: rgb(0, 153, 51);
}
.results-bar .step.good_very_good{
  background-image: linear-gradient(to right, rgb(0, 153, 51), rgb(0, 255, 85));
}

.selected_bar_step, .group_bar_step, .empty_bar_step, .selected_bar_step_non_overlap {
  position: relative;
  text-align: center;
  height: 1.6rem;
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
}
.selected_bar_step div, .group_bar_step div, .selected_bar_step_non_overlap div {
  position: absolute;
  text-align: center;
  font-size: 80%;
  left: 50%;
  margin-left: -3rem;
  width: 6rem;
  padding: 0.2rem;
}
.selected_bar_step div, .selected_bar_step_non_overlap div{
  background-color: $mela-blue;
  color: white;
  margin-bottom: 0.6rem;
}
.group_bar_step div{
  background-color: #eee;
  color: $mela-blue;
}

.smile-wrapper{
  width: 2.5rem;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  margin-top: -0.2rem;
}
.smile-wrapper svg{
  width: 1.9rem;
  height: 1.8rem;
  vertical-align: top;
}
.resultsbar-wrapper{
  width: calc(100% - 5rem);
  display: inline-block;
}
.group_bar_step::before, .selected_bar_step_non_overlap::before {
  content: "";
  position: absolute;
  width: 0.6rem;
  height: 0.6rem;
  background-color: #06038D;
  transform: scaleY(2) translateX(-50%) rotate(
45deg);
  z-index: 0;
}

.group_bar_step::before{
  top: -0.2rem;
}

.selected_bar_step {
  height: 3.2rem;

  &::before {
    content: "";
    position: absolute;
    border: 0.7rem solid transparent;
    border-bottom: 0;
    border-top: 3rem solid $mela-blue;
    bottom: -1rem;
    left: -0.7rem
  }
}

.selected_bar_step_non_overlap::before {
  bottom: -0.2rem;
}

.previous_bar_step {
  position: relative;
  text-align: center;
  height: 1rem;
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;

  .previous_date {
    position: absolute;
    text-align: center;
    font-size: 80%;
    left: 50%;
    margin-left: -1.3rem;
    width: 3rem;
    color: white;
    margin-bottom: 0.6rem;
    z-index: 3;
  }

  .line_position {
    content: "";
    position: absolute;
    width: 0.2rem;
    height: 1rem;
    background-color: rgb(120, 120, 120);
    transform: scaleY(2) translateX(-50%);
    z-index: 0;
  }
}

.line-style {
  background-color: black;
  z-index: 2;
  position: absolute;
  height: 1.5px;
}
