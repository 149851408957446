
.form-question .form-options{
  padding: 0 2rem;
}

.form-question h6{
  color: #333399;
  font-weight: 800;
}
.form-question .form-check{
  margin-bottom: 0.5rem;
}
.form-question .form-check .form-check-input:not([disabled]), .form-question .form-check .form-check-input:not([disabled]) ~ .form-check-label{
  cursor: pointer;
}
.unanswered-question{
  border: 1px solid red;
  padding: 1rem;
}

.q_scale_4  .form-check, .q_scale_5  .form-check, .q_scale_10 .form-check{
  display: inline-block;
  text-align: center;
  width: 16.66%;
  padding: 0.4rem 0;
}
.q_scale_10 .form-check{
  width: 9.09%;
}
.q_scale_4 .form-check{
  width: 20%;
}

.form-question .form-check-input[disabled] ~ .form-check-label, 
.form-question .form-check-input:disabled ~ .form-check-label, 
.form-question .form-check-input:disabled {
  opacity: 0.75;
}

.q_scale_4 .form-check-label, .q_scale_5 .form-check-label, .q_scale_10 .form-check-label{
  width: 100%;
  display: block;
  color: #333399;
}
.q_scale_4 .form-check-input, .q_scale_5 .form-check-input, .q_scale_10 .form-check-input{
  float: inherit;
  margin-left: 0;
  //width: 100%;
  //display: block;
}

.q_table .form-table-option {
  padding: 0.1rem 1rem ;
}
.q_table .form-table-option:nth-child(even){
  background-color: rgb(246, 245, 255);
}

.form-table-option .form-check{
  display: inline-block;
  float: right;
  margin-left: 0.6rem;
  margin-top: 0.2rem;
  margin-bottom: 0;
}

.table-option-title{
  display: inline-block;
  margin: 0.2rem 0;
}
  
.participation-input{
  display: none;
}

.approval-input{
  display: none;
}

.form-opts-horizontal{
  display: flex;
  column-gap: 10rem;
  flex-wrap: wrap;
}